import { useCallback, useEffect, useState } from "react";

const useVisibility = (visibilityProvider: VisibilityProvider) => {
  const [isVisible, setIsVisible] = useState(visibilityProvider.isVisible);

  const listener = useCallback(
    () => setIsVisible(visibilityProvider.isVisible()),
    [setIsVisible, visibilityProvider],
  );

  useEffect(() => {
    document.addEventListener("visibilitychange", listener);

    return () => {
      document.removeEventListener("visibilitychange", listener);
    };
  }, [listener]);

  return [isVisible, setIsVisible];
};

export { useVisibility };
