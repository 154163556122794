const addVisibilityListener = (listener: () => void) => {
  document.addEventListener("visibilitychange", listener);
};

const removeVisibilityListener = (listener: () => void) => {
  document.removeEventListener("visibilitychange", listener);
};

const isVisible = (): boolean => document.visibilityState === "visible";

export { addVisibilityListener, removeVisibilityListener, isVisible };
