import { AppError } from "../entity/app-error";
import { SyncError } from "../entity/sync-error";
import { PREFIX_ALEXA_API } from "../config/api-prefix";

const request = async (
  token: string,
  path: string,
  method: "GET" | "POST" | "PUT" | "DELETE",
  body?: any,
): Promise<Response> => {
  try {
    return await fetch(PREFIX_ALEXA_API + path, {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: body ? JSON.stringify(body) : null,
    });
  } catch (error) {
    console.error(error);
    throw new AppError("NETWORK_ERROR");
  }
};

const handleResponse = (
  response: Response,
  expectedCode: number,
  errorMessage: string,
) => {
  if (response.status === 0) {
    throw new AppError("NETWORK_ERROR");
  } else if (response.status === 403) {
    throw new AppError("AUTH_REQUIRED");
  } else if (response.status !== expectedCode) {
    switch (response.status) {
      case 404:
        throw new SyncError("SYNC_NOT_FOUND_ERROR", errorMessage);
      case 409:
        throw new SyncError("SYNC_CONFLICT_ERROR", errorMessage);
      default:
        throw new AppError("SYNC_ERROR", errorMessage);
    }
  }
};

const loadShoppingItems = async (
  token: string,
  listId: string,
  status: ShoppingItemStatus,
): Promise<ShoppingItemResponse> => {
  const response = await request(
    token,
    `/v2/householdlists/${listId}/${status}`,
    "GET",
  );

  handleResponse(response, 200, "loading shopping items failed");

  return await response.json();
};

const loadShoppingItem = async (
  token: string,
  listId: string,
  itemId: string,
): Promise<ShoppingItem> => {
  const response = await request(
    token,
    `/v2/householdlists/${listId}/items/${itemId}`,
    "GET",
  );

  handleResponse(response, 200, "loading shopping item failed");

  return await response.json();
};

const loadAllShoppingLists = async (
  token: string,
): Promise<ShoppingListResponse> => {
  const response = await request(token, "/v2/householdlists/", "GET");

  handleResponse(response, 200, "loading shopping lists failed");

  return await response.json();
};

const deleteShoppingItem = async (
  token: string,
  listId: string,
  itemId: string,
): Promise<void> => {
  const response = await request(
    token,
    `/v2/householdlists/${listId}/items/${itemId}`,
    "DELETE",
  );

  handleResponse(response, 200, "leting shopping item failed");
};

const updateShoppingItem = async (
  token: string,
  listId: String,
  itemId: string,
  value: string,
  status: ShoppingItemStatus,
  version: number,
): Promise<void> => {
  const response = await request(
    token,
    `/v2/householdlists/${listId}/items/${itemId}`,
    "PUT",
    { value, status, version },
  );

  handleResponse(response, 200, "updating shopping item failed");
};

export {
  loadShoppingItems,
  loadAllShoppingLists,
  deleteShoppingItem,
  updateShoppingItem,
  loadShoppingItem,
};
