import xss from "xss";
import { retrieveTokensByCode } from "../auth/auth-token";
import { setDefaultShoppingListId } from "../default-shopping-list-id";

const checkAuthParameterAndInitializeIfPossible = async (
  secret: string | null,
  addressBarProvider: AddressBarProvider,
  authProvider: AuthProvider,
  storageProvider: StorageProvider,
  shoppingListProvider: ShoppingListProvider,
) => {
  const code = addressBarProvider.readParameterFromAddressBar("code");
  if (code && secret) {
    await retrieveTokensByCode(
      xss(code),
      secret,
      authProvider,
      storageProvider,
    );
    await setDefaultShoppingListId(
      secret,
      authProvider,
      storageProvider,
      shoppingListProvider,
    );
    addressBarProvider.resetAddressBar();
  }
};

export { checkAuthParameterAndInitializeIfPossible };
