import { AppError } from "../entity/app-error";
import { withRefreshToken } from "./auth/auth-token";

const getDefaultShoppingListId = async (
  secret: string,
  authProvider: AuthProvider,
  storageProvider: StorageProvider,
  shoppingListProvider: ShoppingListProvider,
): Promise<string> => {
  const listId = storageProvider.readDefaultShoppingListId();

  if (listId) {
    return listId;
  }

  const response = await withRefreshToken<Promise<ShoppingListResponse>>(
    secret,
    async (token) => await shoppingListProvider.loadAllShoppingLists(token),
    authProvider,
    storageProvider,
  );

  const defaultShoppingList = response.lists.find(
    (list: ShoppingList) =>
      list.name === "Alexa shopping list" && list.state === "active",
  );

  if (defaultShoppingList) {
    return defaultShoppingList.listId;
  }

  throw new AppError("KNOWN_ERROR", "default shopping list is missing");
};

const setDefaultShoppingListId = async (
  secret: string,
  authProvider: AuthProvider,
  storageProvider: StorageProvider,
  shoppingListProvider: ShoppingListProvider,
): Promise<void> => {
  const defaultShoppingListId = await getDefaultShoppingListId(
    secret,
    authProvider,
    storageProvider,
    shoppingListProvider,
  );
  storageProvider.writeDefaultShoppingListId(defaultShoppingListId);
};

const removeDefaultShoppingListId = (
  storageProvider: StorageProvider,
): void => {
  storageProvider.removeDefaultShoppingListId();
};

export {
  getDefaultShoppingListId,
  setDefaultShoppingListId,
  removeDefaultShoppingListId,
};
