import { AppError } from "../../entity/app-error";

const createShoppingItemDeletionMutation = (
  itemId: string,
): ShoppingItemDeletionMutation => ({
  type: "DELETION",
  itemId,
});

const createShoppingItemUpdateMutation = (
  itemId: string,
  value: string,
  status: ShoppingItemStatus,
  version: number,
  mutations: ShoppingItemMutation[],
): ShoppingItemUpdateMutation => {
  const mutation = mutations.find(
    (m) => m.itemId === itemId && m.type === "UPDATE",
  );
  let calculatedVersion = version;
  if (mutation && mutation.type === "UPDATE") {
    calculatedVersion = mutation.data.version;
  } else if (mutation) {
    throw new AppError(
      "KNOWN_ERROR",
      "mutation has wrong type " + mutation.type,
    );
  }

  return {
    type: "UPDATE",
    itemId,
    data: { value, status, version: calculatedVersion },
  };
};

const addShoppingItemMutation = (
  mutationToAdd: ShoppingItemMutation,
  mutations: ShoppingItemMutation[],
  originalShoppingItems: ShoppingItem[],
): ShoppingItemMutation[] => {
  const originalItem = originalShoppingItems.find(
    (item) => mutationToAdd.itemId === item.id,
  );
  const mutationsWithoutItemMutation = mutations.filter(
    (mutationFromSource) => mutationFromSource.itemId !== mutationToAdd.itemId,
  );

  switch (mutationToAdd.type) {
    case "DELETION":
      return originalItem
        ? [...mutationsWithoutItemMutation, mutationToAdd]
        : mutationsWithoutItemMutation;
    case "UPDATE":
      if (originalItem) {
        return originalItem.status === mutationToAdd.data.status &&
          originalItem.value === mutationToAdd.data.value
          ? mutationsWithoutItemMutation
          : [...mutationsWithoutItemMutation, mutationToAdd];
      }
      return mutationsWithoutItemMutation;

    default:
      throw new AppError(
        "KNOWN_ERROR",
        "unknown mutation type " + mutationToAdd.type,
      );
  }
};

export {
  createShoppingItemDeletionMutation,
  createShoppingItemUpdateMutation,
  addShoppingItemMutation,
};
