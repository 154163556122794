const errorReducer = (
  oldState: ErrorState,
  action: ErrorAction,
): ErrorState => {
  switch (action.type) {
    case "HANDLE_ERROR":
      return { ...oldState, error: action.payload.error };
    case "RESET_ERROR":
      return { ...oldState, error: null };
    default:
      return oldState;
  }
};

export { errorReducer };
