import { useContext } from "react";
import "./settings-screen.css";
import { Button } from "../button/button";
import * as authProvider from "../../api/auth-provider";
import * as storageProvider from "../../api/storage-provider";
import * as locationProvider from "../../api/location-provider";
import { BottomBar } from "../bottom-bar/bottom-bar";
import { VERSION } from "../../config/version";
import { BUILD } from "../../config/build";
import { AppContext } from "../../domain/app/app-context";
import { useApp } from "../../domain/app/app-hook";
import { useNavigation } from "../../domain/navigation/navigation-hook";
import { useShoppingList } from "../../domain/shopping-list/shopping-list-hook";

const SettingsScreen = ({ secret }: SettingsScreenProps) => {
  const context = useContext(AppContext);
  const { state } = context;

  const { resetApp, revokeApp } = useApp(
    secret,
    context,
    authProvider,
    storageProvider,
    locationProvider,
  );

  const { gotoScreen } = useNavigation(context);

  const { deleteCompletedShoppingItems } = useShoppingList(context);

  return (
    <div className="settings-screen">
      <h1>Einstellungen</h1>
      <span>
        Version: {VERSION} Build: {BUILD}
      </span>
      <Button
        text="Erledigte Einträge löschen"
        onClick={deleteCompletedShoppingItems}
      />
      <Button text="App zurücksetzen" onClick={resetApp} />
      <Button text="Zugriffsrechte entziehen" onClick={revokeApp} />
      <BottomBar
        currentScreen={state.navigationState.currentScreen}
        onClick={gotoScreen}
      />
    </div>
  );
};

export { SettingsScreen };
