import { useContext } from "react";
import { AppError } from "../../entity/app-error";
import "./error-modal.css";
import { Button } from "../button/button";
import { AppContext } from "../../domain/app/app-context";
import { resetErrorAction } from "../../domain/error/error-action";

export const ErrorModal = () => {
  const { state, dispatch } = useContext(AppContext);
  const error = state.errorState.error;

  const resetError = () => {
    resetErrorAction(dispatch);
  };

  let errorMessage = null;
  let modalAction = null;

  if (error && error instanceof Error) {
    const defaultErrorMessage = `Es ist ein Fehler aufgetreten: ${error.message}!`;
    const defaultModalAction = <Button text="OK" onClick={resetError} />;

    if (error instanceof AppError) {
      switch (error.errorType) {
        case "AUTH_REQUIRED":
          errorMessage =
            "Für die Synchronisierung ist eine erneute Anmledung erforderlich!";
          modalAction = defaultModalAction;
          break;
        case "NETWORK_ERROR":
          errorMessage = "Es liegt ein Problem mit der Netwerkverbindung vor!";
          modalAction = defaultModalAction;
          break;
        case "TOKEN_ERROR":
          errorMessage =
            "Es konnte kein Token abgerufen werden. Evtentuell ist dies ein Hinweis dafür, dass das Token kompromitiert wurde. Bitte den App-Zugriff entfernen!";
          modalAction = defaultModalAction;
          break;
        default:
          errorMessage = defaultErrorMessage;
          modalAction = defaultModalAction;
      }
    } else {
      errorMessage = defaultErrorMessage;
      modalAction = defaultModalAction;
    }
  }

  return error ? (
    <div className="app-error-modal" onClick={resetError}>
      <div className="box">
        {errorMessage}
        {modalAction}
      </div>
    </div>
  ) : null;
};
