import { createContext } from "react";
import { getInitialAppState } from "./app-state";
import * as storageProvider from "../../api/storage-provider";

const AppContext = createContext<AppContext>({
  state: getInitialAppState(storageProvider),
  dispatch: () => {},
});

export { AppContext };
