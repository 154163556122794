const KEY_ACCESS_TOKEN = "access_token";
const KEY_REFRESH_TOKEN = "refresh_token";
const KEY_DEFAULT_SHOPPING_LIST_ID = "default_shopping_list_id";
const KEY_ACTIVE_SHOPPING_ITEMS = "active_shopping_items";
const KEY_COMPLETED_SHOPPING_ITEMS = "completed_shopping_items";
const KEY_SHOPPING_LIST_MUTATIONS = "shopping_list_mutations";
const KEY_SECRET = "secret";

const serializeShoppingItems = (items: ShoppingItem[]) => JSON.stringify(items);
const deserializeShoppingItems = (items: string) =>
  JSON.parse(items).map((item: ShoppingItem) => ({
    ...item,
    createdTime: new Date(item.createdTime),
    updatedTime: new Date(item.createdTime),
  }));

const readAccessToken = (): string | null =>
  localStorage.getItem(KEY_ACCESS_TOKEN);
const writeAccessToken = (code: string): void =>
  localStorage.setItem(KEY_ACCESS_TOKEN, code);
const removeAccessToken = () => localStorage.removeItem(KEY_ACCESS_TOKEN);
const readRefreshToken = (): string | null =>
  localStorage.getItem(KEY_REFRESH_TOKEN);
const writeRefreshToken = (refreshToken: string): void =>
  localStorage.setItem(KEY_REFRESH_TOKEN, refreshToken);
const removeRefreshToken = () => localStorage.removeItem(KEY_REFRESH_TOKEN);
const readSecret = (persist: boolean = true): string | null =>
  persist
    ? localStorage.getItem(KEY_SECRET)
    : sessionStorage.getItem(KEY_SECRET);
const writeSecret = (code: string, persist: boolean = true): void =>
  persist
    ? localStorage.setItem(KEY_SECRET, code)
    : sessionStorage.setItem(KEY_SECRET, code);
const removeSecret = (persist: boolean = true) =>
  persist
    ? localStorage.removeItem(KEY_SECRET)
    : sessionStorage.removeItem(KEY_SECRET);
const readDefaultShoppingListId = (): string | null =>
  localStorage.getItem(KEY_DEFAULT_SHOPPING_LIST_ID);
const writeDefaultShoppingListId = (id: string): void =>
  localStorage.setItem(KEY_DEFAULT_SHOPPING_LIST_ID, id);
const removeDefaultShoppingListId = (): void =>
  localStorage.removeItem(KEY_DEFAULT_SHOPPING_LIST_ID);
const readActiveShoppingItems = (): ShoppingItem[] => {
  const items = localStorage.getItem(KEY_ACTIVE_SHOPPING_ITEMS);
  if (items) {
    return deserializeShoppingItems(items);
  }
  return [];
};
const writeActiveShoppingItems = (items: ShoppingItem[]): void =>
  localStorage.setItem(
    KEY_ACTIVE_SHOPPING_ITEMS,
    serializeShoppingItems(items),
  );
const removeActiveShoppingItems = (): void => {
  localStorage.removeItem(KEY_ACTIVE_SHOPPING_ITEMS);
};
const readCompletedShoppingItems = (): ShoppingItem[] => {
  const items = localStorage.getItem(KEY_COMPLETED_SHOPPING_ITEMS);
  if (items) {
    return deserializeShoppingItems(items);
  }
  return [];
};
const writeCompletedShoppingItems = (items: ShoppingItem[]): void =>
  localStorage.setItem(
    KEY_COMPLETED_SHOPPING_ITEMS,
    serializeShoppingItems(items),
  );
const removeCompletedShoppingItems = (): void => {
  localStorage.removeItem(KEY_COMPLETED_SHOPPING_ITEMS);
};
const readShoppingListMutations = (): ShoppingItemMutation[] => {
  const mutations = localStorage.getItem(KEY_SHOPPING_LIST_MUTATIONS);
  if (mutations) {
    return JSON.parse(mutations);
  }
  return [];
};
const writeShoppingListMutations = (mutations: ShoppingItemMutation[]): void =>
  localStorage.setItem(KEY_SHOPPING_LIST_MUTATIONS, JSON.stringify(mutations));
const removeShoppingListMutations = (): void => {
  localStorage.removeItem(KEY_SHOPPING_LIST_MUTATIONS);
};

export {
  readAccessToken,
  writeAccessToken,
  removeAccessToken,
  readRefreshToken,
  writeRefreshToken,
  removeRefreshToken,
  readSecret,
  writeSecret,
  removeSecret,
  readDefaultShoppingListId,
  writeDefaultShoppingListId,
  removeDefaultShoppingListId,
  readActiveShoppingItems,
  writeActiveShoppingItems,
  removeActiveShoppingItems,
  readCompletedShoppingItems,
  writeCompletedShoppingItems,
  removeCompletedShoppingItems,
  readShoppingListMutations,
  writeShoppingListMutations,
  removeShoppingListMutations,
};
