const isOnline = (): boolean => window.navigator.onLine;

const addConnectivityListener = (listener: () => void): void => {
  window.addEventListener("online", listener);
  window.addEventListener("offline", listener);
};

const removeConnectivityListener = (listener: () => void): void => {
  window.removeEventListener("online", listener);
  window.removeEventListener("offline", listener);
};

export { isOnline, addConnectivityListener, removeConnectivityListener };
