import { handleErrorAction } from "../error/error-action";
import { gotoScreenAction } from "../navigation/navigation-action";
import { isAuthenticated } from "../auth/auth-login";
import { useEffect } from "react";
import { checkAuthParameterAndInitializeIfPossible } from "./app-init";

const useAppInitialization = (
  secret: string | null,
  context: AppContext,
  authProvider: AuthProvider,
  storageProvider: StorageProvider,
  shoppingListProvider: ShoppingListProvider,
  addressBarProvider: AddressBarProvider,
) => {
  const { dispatch } = context;

  useEffect(() => {
    const initApp = async () => {
      try {
        if (isAuthenticated(storageProvider)) {
          gotoScreenAction(dispatch, "SHOPPING_LIST");
        } else {
          await checkAuthParameterAndInitializeIfPossible(
            secret,
            addressBarProvider,
            authProvider,
            storageProvider,
            shoppingListProvider,
          );
          if (isAuthenticated(storageProvider)) {
            gotoScreenAction(dispatch, "SHOPPING_LIST");
          } else {
            gotoScreenAction(dispatch, "LOGIN");
          }
        }
      } catch (error) {
        handleErrorAction(dispatch, error);
        gotoScreenAction(dispatch, "LOGIN");
      }
    };

    initApp();
  }, [
    secret,
    addressBarProvider,
    authProvider,
    dispatch,
    shoppingListProvider,
    storageProvider,
  ]);
};

export { useAppInitialization };
