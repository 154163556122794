const makeDeferred = <T>() =>
  ((): DeferredPromise<T> => {
    let resolve, reject;
    const promise = new Promise<T>((_resolve, _reject) => {
      resolve = _resolve;
      reject = _reject;
    });
    if (resolve && reject) {
      return {
        promise,
        resolve,
        reject,
      };
    }

    throw new Error("error creating deferred promise");
  })();

const queue: QueueItem<any>[] = [];
const runInQueue = async <T>(action: () => Promise<T>): Promise<T> => {
  const sameActions = queue.filter((i) => i.action === action);
  if (sameActions.length < 2) {
    queue.push({ action, deferred: makeDeferred() });
  }

  const run = async (item: QueueItem<T>) => {
    try {
      const index = queue.indexOf(item);
      if (index > -1) {
        queue.splice(index, 1);
      }

      const result = await item.action();

      item.deferred.resolve(result);

      if (queue.length) {
        await run(queue[0]);
      }
    } catch (error) {
      item.deferred.reject(error);
    }
  };

  const promise = queue.filter((i) => i.action === action).reverse()[0]
    .deferred.promise;
  if (queue.length === 1) {
    await run(queue[0]);
  }

  return await promise;
};

const runRecursively = async <T>(
  items: T[],
  action: (item: T, next: () => Promise<void>) => Promise<void>,
) => {
  const recursive = async (handledItems: T[]) => {
    const unhandledItems = items.filter(
      (item) => !handledItems.find((handledItem) => item === handledItem),
    );

    if (unhandledItems.length) {
      const item = unhandledItems[0];
      const next = async () => {
        await recursive([...handledItems, item]);
      };
      await action(item, next);
    }
  };

  await recursive([]);
};

export { runInQueue, makeDeferred, runRecursively };
