import { useContext } from "react";
import { AppContext } from "../../domain/app/app-context";
import * as storageProvider from "../../api/storage-provider";
import * as authProvider from "../../api/auth-provider";
import * as shoppingListProvider from "../../api/shopping-list-provider";
import * as addressBarProvider from "../../api/address-bar-provider";
import { LoginScreen } from "../../screen/login-screen/login-screen";
import { ShoppingListScreen } from "../shopping-list/shopping-list-screen";
import { SettingsScreen } from "../settings/settings-screen";
import { ErrorModal } from "../error-modal/error-modal";
import { useAppInitialization } from "../../domain/app/app-init-hook";

const Navigation = ({ secret }: { secret: string | null }) => {
  const context = useContext(AppContext);
  const { state } = context;

  useAppInitialization(
    secret,
    context,
    authProvider,
    storageProvider,
    shoppingListProvider,
    addressBarProvider,
  );

  const selectScreen = (screen: ScreenType | undefined) => {
    if (!secret) {
      return <LoginScreen />;
    }

    switch (screen) {
      case "LOGIN":
        return <LoginScreen />;

      case "SHOPPING_LIST":
        return <ShoppingListScreen secret={secret} />;

      case "SETTINGS":
        return <SettingsScreen secret={secret} />;

      default:
        return null;
    }
  };

  return (
    <div>
      {selectScreen(state.navigationState.currentScreen)}
      <ErrorModal />
    </div>
  );
};

export { Navigation };
