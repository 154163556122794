class AppError extends Error {
  public errorType: AppErrorType;
  constructor(type: AppErrorType, message?: string) {
    let defaultMessage;
    switch (type) {
      case "AUTH_REQUIRED":
        defaultMessage = "auth required";
        break;
      case "KNOWN_ERROR":
        defaultMessage = "network error";
        break;
      case "NETWORK_ERROR":
        defaultMessage = "network error";
        break;
      default:
        defaultMessage = "error occured";
    }

    super(message || defaultMessage);
    this.errorType = type;
    Object.setPrototypeOf(this, AppError.prototype);
  }
}

export { AppError };
