import { Dispatch } from "react";

const gotoScreenAction = (
  dispatch: Dispatch<GotoScreenAction>,
  screen: ScreenType,
) => {
  dispatch({
    type: "GOTO_SCREEN_ACTION",
    payload: { screen },
  });
};

export { gotoScreenAction };
