import { AppError } from "../../entity/app-error";

const sortShoppingListByCreatedDateDesc = (items: ShoppingItem[]) =>
  items.sort(
    (a: ShoppingItem, b: ShoppingItem) =>
      b.createdTime.getTime() - a.createdTime.getTime(),
  );

const sortNothing = (items: ShoppingItem[]) => items;

const getShoppingListSortFunctionBySortType = (
  type: SortType,
): ShoppingListSorting => {
  switch (type) {
    case "DEFAULT":
      return {
        sortActiveShoppingItems: sortShoppingListByCreatedDateDesc,
        sortCompletedShoppingItems: sortNothing,
      };
    default:
      throw new AppError("KNOWN_ERROR", "unknown sort type: " + type);
  }
};

export { getShoppingListSortFunctionBySortType };
