import { gotoScreenAction } from "./navigation-action";

const useNavigation = (context: AppContext) => {
  const { dispatch } = context;

  const gotoScreen = (screen: ScreenType) => {
    gotoScreenAction(dispatch, screen);
  };

  return { gotoScreen };
};

export { useNavigation };
