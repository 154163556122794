import { AppError } from "../../entity/app-error";
import { SyncError } from "../../entity/sync-error";
import { runInQueue, runRecursively } from "../../util/control-flow";
import { withRefreshToken } from "../auth/auth-token";
import { getDefaultShoppingListId } from "../default-shopping-list-id";
import { applyMutationToShoppingItems } from "./shopping-list-mutation-apply";

const applyMutationToStoredShoppingItems = (
  mutation: ShoppingItemMutation,
  updateCallback: (state: UpdateShoppingListStatePayload) => void,
  storageProvider: StorageProvider,
): void => {
  const activeShoppingItems = storageProvider.readActiveShoppingItems();
  const completedShoppingItems = storageProvider.readCompletedShoppingItems();
  const itemsWithAppliedMutation = applyMutationToShoppingItems(
    mutation,
    activeShoppingItems,
    completedShoppingItems,
  );
  storageProvider.writeActiveShoppingItems(itemsWithAppliedMutation.active);
  storageProvider.writeCompletedShoppingItems(
    itemsWithAppliedMutation.completed,
  );

  const mutations = storageProvider.readShoppingListMutations();
  const newMutations = mutations.filter((m) => m.itemId !== mutation.itemId);
  storageProvider.writeShoppingListMutations(newMutations);

  updateCallback({
    mutations: newMutations,
    activeShoppingItems: itemsWithAppliedMutation.active,
    completedShoppingItems: itemsWithAppliedMutation.completed,
    originalShoppingItems: itemsWithAppliedMutation.active.concat(
      itemsWithAppliedMutation.completed,
    ),
  });
};

const retryUpdateMutationUpload = async (
  secret: string,
  mutation: ShoppingItemUpdateMutation,
  authProvider: AuthProvider,
  storageProvider: StorageProvider,
  shoppingListProvider: ShoppingListProvider,
) => {
  const listId = await getDefaultShoppingListId(
    secret,
    authProvider,
    storageProvider,
    shoppingListProvider,
  );
  const item = await withRefreshToken<Promise<ShoppingItem>>(
    secret,
    async (token) =>
      await shoppingListProvider.loadShoppingItem(
        token,
        listId,
        mutation.itemId,
      ),
    authProvider,
    storageProvider,
  );
  const newMutation = {
    ...mutation,
    data: { ...mutation.data, version: item.version },
  };
  storageProvider.writeShoppingListMutations(
    storageProvider.readShoppingListMutations().map((m) => {
      if (m.itemId === newMutation.itemId) {
        return newMutation;
      } else {
        return m;
      }
    }),
  );
  await uploadShoppingItemMutation(
    secret,
    newMutation,
    authProvider,
    storageProvider,
    shoppingListProvider,
  );
};

const uploadShoppingItemMutation = async (
  secret: string,
  mutation: ShoppingItemMutation,
  authProvider: AuthProvider,
  storageProvider: StorageProvider,
  shoppingListProvider: ShoppingListProvider,
) => {
  const listId = await getDefaultShoppingListId(
    secret,
    authProvider,
    storageProvider,
    shoppingListProvider,
  );

  switch (mutation.type) {
    case "UPDATE":
      await withRefreshToken<Promise<void>>(
        secret,
        async (token) =>
          await shoppingListProvider.updateShoppingItem(
            token,
            listId,
            mutation.itemId,
            mutation.data.value,
            mutation.data.status,
            mutation.data.version,
          ),
        authProvider,
        storageProvider,
      );
      break;
    case "DELETION":
      await withRefreshToken<Promise<void>>(
        secret,
        async (token) =>
          await shoppingListProvider.deleteShoppingItem(
            token,
            listId,
            mutation.itemId,
          ),
        authProvider,
        storageProvider,
      );
      break;
  }
};

const uploadShoppingItemMutations = async (
  secret: string,
  updateCallback: (state: UpdateShoppingListStatePayload) => void,
  authProvider: AuthProvider,
  storageProvider: StorageProvider,
  shoppingListProvider: ShoppingListProvider,
): Promise<void> =>
  await runInQueue<void>(async () => {
    const mutations = storageProvider.readShoppingListMutations();
    if (mutations.length) {
      await runRecursively(
        mutations,
        async (mutation: ShoppingItemMutation, next: () => Promise<void>) => {
          const continueUpload = async () => {
            applyMutationToStoredShoppingItems(
              mutation,
              updateCallback,
              storageProvider,
            );
            await next();
          };

          try {
            await uploadShoppingItemMutation(
              secret,
              mutation,
              authProvider,
              storageProvider,
              shoppingListProvider,
            );
            await continueUpload();
          } catch (error) {
            if (error instanceof SyncError) {
              switch (error.errorType) {
                case "SYNC_CONFLICT_ERROR":
                  if (mutation.type === "UPDATE") {
                    try {
                      await retryUpdateMutationUpload(
                        secret,
                        mutation,
                        authProvider,
                        storageProvider,
                        shoppingListProvider,
                      );
                      await continueUpload();
                    } catch (e) {
                      if (e instanceof SyncError) {
                        switch (e.errorType) {
                          case "SYNC_CONFLICT_ERROR":
                          case "SYNC_NOT_FOUND_ERROR":
                            await continueUpload();
                            break;
                          default:
                            throw new AppError("SYNC_ERROR", error.message);
                        }
                      } else {
                        throw error;
                      }
                    }
                  } else {
                    await continueUpload();
                  }
                  break;
                case "SYNC_NOT_FOUND_ERROR":
                  await continueUpload();
                  break;
                default:
                  throw new AppError("SYNC_ERROR", error.message);
              }
            } else {
              throw error;
            }
          }
        },
      );
    }
  });

export { uploadShoppingItemMutations };
