const startAnimation = (minDuration: number) => {
  const startTime = Date.now();
  return {
    endAnimation: async (): Promise<void> => {
      const elapsedTime = Date.now() - startTime;
      await new Promise<void>((resolve) => {
        if (elapsedTime < minDuration) {
          setTimeout(() => {
            resolve();
          }, minDuration - elapsedTime);
        } else {
          resolve();
        }
      });
    },
  };
};

export { startAnimation };
