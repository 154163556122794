import { Dispatch } from "react";

const updateShoppingListStateAction = (
  dispatch: Dispatch<UpdateShoppingListStateAction>,
  state: UpdateShoppingListStatePayload,
) => {
  dispatch({
    type: "UPDATE_SHOPPING_LIST_STATE",
    payload: state,
  });
};

const completeShoppingItemAction = (
  dispatch: Dispatch<CompleteShoppingItemAction>,
  itemId: string,
) => {
  dispatch({
    type: "COMPLETE_SHOPPING_ITEM",
    payload: {
      itemId,
    },
  });
};

const activateShoppingItemAction = (
  dispatch: Dispatch<ActivateShoppingItemAction>,
  itemId: string,
) => {
  dispatch({
    type: "ACTIVATE_SHOPPING_ITEM",
    payload: {
      itemId,
    },
  });
};

const deleteAllCompletedShoppingItemsAction = (
  dispatch: Dispatch<DeleteAllCompletedShoppingItemsAction>,
) => {
  dispatch({
    type: "DELETE_ALL_COMPLETED_SHOPPING_ITEMS",
  });
};

export {
  updateShoppingListStateAction,
  completeShoppingItemAction,
  activateShoppingItemAction,
  deleteAllCompletedShoppingItemsAction,
};
