import { AppError } from "../../entity/app-error";
import { decrypt, sha256 } from "../../util/crypto";

const login = (
  authProvider: AuthProvider,
  storageProvider: StorageProvider,
) => {
  storageProvider.writeSecret("schnuffi");
  authProvider.login();
};

const isAuthenticated = (storageProvider: StorageProvider) => {
  const token = storageProvider.readAccessToken();

  if (token) {
    return true;
  }

  return false;
};

const logout = async (
  secret: string,
  authProvider: AuthProvider,
  storageProvider: StorageProvider,
) => {
  const encryptedRefreshToken = storageProvider.readRefreshToken();
  if (encryptedRefreshToken) {
    const hashedSecret = await sha256(secret);
    const refreshToken = await decrypt(encryptedRefreshToken, secret);
    await authProvider.logout(refreshToken, hashedSecret);
    storageProvider.removeAccessToken();
    storageProvider.removeRefreshToken();
    storageProvider.removeSecret();
  } else {
    throw new AppError("KNOWN_ERROR", "missing refresh token");
  }
};

export { login, logout, isAuthenticated };
