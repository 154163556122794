import { logout } from "../auth/auth-login";
import { removeDefaultShoppingListId } from "../default-shopping-list-id";

const resetAndLogout = async (
  secret: string,
  authProvider: AuthProvider,
  storageProvider: StorageProvider,
) => {
  await logout(secret, authProvider, storageProvider);

  removeDefaultShoppingListId(storageProvider);
  storageProvider.removeActiveShoppingItems();
  storageProvider.removeCompletedShoppingItems();
  storageProvider.removeShoppingListMutations();
};

export { resetAndLogout };
