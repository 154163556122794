import { useContext } from "react";
import "./shopping-list-screen.css";
import { Button } from "../button/button";
import { AppContext } from "../../domain/app/app-context";
import { useShoppingList } from "../../domain/shopping-list/shopping-list-hook";
import * as authProvider from "../../api/auth-provider";
import * as storageProvider from "../../api/storage-provider";
import * as shoppingListProvider from "../../api/shopping-list-provider";
import * as visibilityProvider from "../../api/visibility-provider";
import * as connectivityProvider from "../../api/connectivity-provider";
import { BottomBar } from "../bottom-bar/bottom-bar";
import { useNavigation } from "../../domain/navigation/navigation-hook";
import { useAuth } from "../../domain/auth/auth-hook";
import { useShoppingListSync } from "../../domain/shopping-list/shopping-list-sync-hook";

export const ShoppingListScreen = ({ secret }: { secret: string }) => {
  const context = useContext(AppContext);
  const { state } = context;
  const activeShoppingItems = state.shoppingListState.activeShoppingItems;
  const completedShoppingItems = state.shoppingListState.completedShoppingItems;

  const { activateShoppingItem, completeShoppingItem, isUnsyncedShoppingItem } =
    useShoppingList(context);

  const {
    updateShoppingList,
    changesCount,
    isOffline,
    isAuthenticationRequired,
    isLoading,
  } = useShoppingListSync(
    secret,
    context,
    authProvider,
    storageProvider,
    shoppingListProvider,
    visibilityProvider,
    connectivityProvider,
  );

  const { gotoScreen } = useNavigation(context);

  const { startLogin } = useAuth(context, authProvider, storageProvider);

  const getUnsyncedSuffix = (itemId: string): string | null =>
    isUnsyncedShoppingItem(itemId) ? "*" : null;

  return (
    <div className="shopping-list-screen">
      <h1>Einkaufsliste</h1>
      {isAuthenticationRequired ? (
        <Button
          text="Erneut Anmelden"
          onClick={startLogin}
          disabled={isLoading || isOffline}
        />
      ) : (
        <>
          <Button
            text="Aktualisieren"
            onClick={updateShoppingList}
            disabled={isLoading || isOffline}
          />
          <sup>{changesCount}</sup>
        </>
      )}

      {isLoading ? (
        <p>Lade...</p>
      ) : (
        <div className="list">
          <ul>
            {activeShoppingItems.map((item: ShoppingItem) => {
              const completeShoppingItemClick = async () => {
                completeShoppingItem(item.id);
              };

              return (
                <li
                  key={item.id}
                  className="item"
                  onClick={completeShoppingItemClick}
                >
                  {item.value}
                  {getUnsyncedSuffix(item.id)}
                </li>
              );
            })}
          </ul>
          <hr />
          <ul>
            {completedShoppingItems.map((item: ShoppingItem) => {
              const activateShoppingItemClick = async () => {
                activateShoppingItem(item.id);
              };
              return (
                <li
                  key={item.id}
                  className="item completed"
                  onClick={activateShoppingItemClick}
                >
                  {item.value}
                  {getUnsyncedSuffix(item.id)}
                </li>
              );
            })}
          </ul>
        </div>
      )}
      <BottomBar
        currentScreen={state.navigationState.currentScreen}
        onClick={gotoScreen}
      />
    </div>
  );
};
