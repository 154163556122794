import { handleErrorAction } from "../error/error-action";
import { gotoScreenAction } from "../navigation/navigation-action";
import { resetAndLogout } from "./app-reset";
import { REVOKE_PERMISSION_URL } from "../../config/revoke-permission-url";
import { AppError } from "../../entity/app-error";

const useApp = (
  secret: string | null,
  context: AppContext,
  authProvider: AuthProvider,
  storageProvider: StorageProvider,
  locationProvider: LocationProvider,
) => {
  const { dispatch } = context;

  const resetApp = async () => {
    try {
      if (secret) {
        await resetAndLogout(secret, authProvider, storageProvider);
      } else {
        handleErrorAction(
          dispatch,
          new AppError("KNOWN_ERROR", "secret not available"),
        );
      }
      gotoScreenAction(dispatch, "LOGIN");
    } catch (error) {
      handleErrorAction(dispatch, error);
    }
  };

  const revokeApp = () => {
    locationProvider.openURL(REVOKE_PERMISSION_URL, "_blank");
  };

  return {
    revokeApp,
    resetApp,
  };
};

export { useApp };
