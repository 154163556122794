import { AppError } from "../../entity/app-error";
import {
  applyMutationToShoppingItems,
  applyMutationsToShoppingItems,
} from "./shopping-list-mutation-apply";
import {
  addShoppingItemMutation,
  createShoppingItemDeletionMutation,
  createShoppingItemUpdateMutation,
} from "./shopping-list-mutation-create";
import { getShoppingListSortFunctionBySortType } from "./shopping-list-sort";

const shoppingListReducer = (
  oldState: ShoppingListState,
  action: ShoppingListAction,
) => {
  const sortFunctions = getShoppingListSortFunctionBySortType(oldState.sort);
  switch (action.type) {
    case "UPDATE_SHOPPING_LIST_STATE":
      const payloadMutations = action.payload.mutations;
      const payloadActiveShoppingItems = action.payload.activeShoppingItems;
      const payloadCompletedShoppingItems =
        action.payload.completedShoppingItems;

      const payloadOriginalSoppingItems = action.payload.originalShoppingItems;

      const updateShoppingListMutations = payloadMutations
        ? payloadMutations
        : oldState.mutations;
      const activeShoppingItems = payloadActiveShoppingItems
        ? payloadActiveShoppingItems
        : oldState.activeShoppingItems;
      const completedShoppingItems = payloadCompletedShoppingItems
        ? payloadCompletedShoppingItems
        : oldState.completedShoppingItems;
      const originalShoppingItems = payloadOriginalSoppingItems
        ? payloadOriginalSoppingItems
        : oldState.originalShoppingItems;

      const itemsWithAppliedMutations = applyMutationsToShoppingItems(
        updateShoppingListMutations,
        activeShoppingItems,
        completedShoppingItems,
      );
      const newShoppingListState: ShoppingListState = {
        ...oldState,
        activeShoppingItems: sortFunctions.sortActiveShoppingItems(
          itemsWithAppliedMutations.active,
        ),
        completedShoppingItems: sortFunctions.sortCompletedShoppingItems(
          itemsWithAppliedMutations.completed,
        ),
        originalShoppingItems,
        mutations: updateShoppingListMutations,
      };
      if (JSON.stringify(oldState) === JSON.stringify(newShoppingListState)) {
        return oldState;
      }
      return newShoppingListState;

    case "COMPLETE_SHOPPING_ITEM":
      const itemToComplete = oldState.activeShoppingItems.find(
        (item) => item.id === action.payload.itemId,
      );
      if (itemToComplete) {
        const mutation = createShoppingItemUpdateMutation(
          itemToComplete.id,
          itemToComplete.value,
          "completed",
          itemToComplete.version,
          oldState.mutations,
        );
        const mutations = addShoppingItemMutation(
          mutation,
          oldState.mutations,
          oldState.originalShoppingItems,
        );

        const itemsWithAppliedMutation = applyMutationToShoppingItems(
          mutation,
          oldState.activeShoppingItems,
          oldState.completedShoppingItems,
        );

        return {
          ...oldState,
          activeShoppingItems: sortFunctions.sortActiveShoppingItems(
            itemsWithAppliedMutation.active,
          ),
          completedShoppingItems: sortFunctions.sortCompletedShoppingItems(
            itemsWithAppliedMutation.completed,
          ),
          mutations,
        };
      } else {
        throw new AppError(
          "KNOWN_ERROR",
          "shopping item for completion not found",
        );
      }

    case "ACTIVATE_SHOPPING_ITEM":
      const itemToActivate = oldState.completedShoppingItems.find(
        (item) => item.id === action.payload.itemId,
      );
      if (itemToActivate) {
        const mutation = createShoppingItemUpdateMutation(
          itemToActivate.id,
          itemToActivate.value,
          "active",
          itemToActivate.version,
          oldState.mutations,
        );
        const mutations = addShoppingItemMutation(
          mutation,
          oldState.mutations,
          oldState.originalShoppingItems,
        );

        const itemsWithAppliedMutation = applyMutationToShoppingItems(
          mutation,
          oldState.activeShoppingItems,
          oldState.completedShoppingItems,
        );

        return {
          ...oldState,
          activeShoppingItems: sortFunctions.sortActiveShoppingItems(
            itemsWithAppliedMutation.active,
          ),
          completedShoppingItems: sortFunctions.sortCompletedShoppingItems(
            itemsWithAppliedMutation.completed,
          ),
          mutations,
        };
      } else {
        throw new AppError(
          "KNOWN_ERROR",
          "shopping item for activation not found",
        );
      }

    case "DELETE_ALL_COMPLETED_SHOPPING_ITEMS":
      const deleteAllCompletedShoppingItemsMutations =
        oldState.completedShoppingItems
          .map((item) => createShoppingItemDeletionMutation(item.id))
          .reduce(
            (oldMutations, mutation) =>
              addShoppingItemMutation(
                mutation,
                oldMutations,
                oldState.originalShoppingItems,
              ),
            oldState.mutations,
          );

      return {
        ...oldState,
        completedShoppingItems: [],
        mutations: deleteAllCompletedShoppingItemsMutations,
      };

    default:
      return oldState;
  }
};

export { shoppingListReducer };
