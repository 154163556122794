const createPolling = (
  seconds: number = 10,
  callbackParam?: () => Promise<void>,
): Polling => {
  let intervalRef: any;
  let callback = callbackParam;

  return {
    setCallback: (callbackParam: () => Promise<void>) => {
      callback = callbackParam;
    },
    start: () => {
      if (!intervalRef) {
        if (callback) {
          callback();
        }
        intervalRef = setInterval(() => {
          if (callback) {
            callback();
          } else {
            throw Error("no callback is set");
          }
        }, seconds * 1000);
      }
    },
    stop: () => {
      if (intervalRef) {
        clearInterval(intervalRef);
        intervalRef = undefined;
      }
    },
  };
};

export { createPolling };
