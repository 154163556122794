class SyncError extends Error {
  public errorType: SyncErrorType;
  constructor(type: SyncErrorType, message?: string) {
    let defaultMessage;
    switch (type) {
      case "SYNC_CONFLICT_ERROR":
        defaultMessage = "conflict during sync";
        break;
      case "SYNC_NOT_FOUND_ERROR":
        defaultMessage = "Syncing item does not exist";
        break;
      default:
        defaultMessage = "sync error occured";
    }

    super(message || defaultMessage);
    this.errorType = type;
    Object.setPrototypeOf(this, SyncError.prototype);
  }
}

export { SyncError };
