import {
  activateShoppingItemAction,
  completeShoppingItemAction,
  deleteAllCompletedShoppingItemsAction,
} from "./shopping-list-action";
import { handleErrorAction } from "../error/error-action";

const useShoppingList = (context: AppContext) => {
  const { state, dispatch } = context;

  const mutations = state.shoppingListState.mutations;

  const isUnsyncedShoppingItem = (itemId: string): boolean =>
    !!mutations.find((mutation) => mutation.itemId === itemId);

  const completeShoppingItem = async (itemId: string) => {
    completeShoppingItemAction(dispatch, itemId);
  };

  const activateShoppingItem = async (itemId: string) => {
    activateShoppingItemAction(dispatch, itemId);
  };

  const deleteCompletedShoppingItems = async () => {
    try {
      await deleteAllCompletedShoppingItemsAction(dispatch);
    } catch (error) {
      handleErrorAction(dispatch, error);
    }
  };

  return {
    isUnsyncedShoppingItem,
    completeShoppingItem,
    activateShoppingItem,
    deleteCompletedShoppingItems,
  };
};

export { useShoppingList };
