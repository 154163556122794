import { applyMutationsToShoppingItems } from "./shopping-list-mutation-apply";
import { getShoppingListSortFunctionBySortType } from "./shopping-list-sort";

const getEmptyShoppingListState = (): ShoppingListState => ({
  activeShoppingItems: [],
  completedShoppingItems: [],
  originalShoppingItems: [],
  mutations: [],
  sort: "DEFAULT",
});

const getInitialShoppingListState = (
  storageProvider: StorageProvider,
): ShoppingListState => {
  const emptyShoppingListState = getEmptyShoppingListState();
  const initialSortType = emptyShoppingListState.sort;
  const sortFunctions = getShoppingListSortFunctionBySortType(initialSortType);

  const mutations = storageProvider.readShoppingListMutations();
  const activeShoppingItems = storageProvider.readActiveShoppingItems();
  const completedShoppingItems = storageProvider.readCompletedShoppingItems();

  const itemsWithAppliedMutations = applyMutationsToShoppingItems(
    mutations,
    activeShoppingItems,
    completedShoppingItems,
  );

  return {
    activeShoppingItems: sortFunctions.sortActiveShoppingItems(
      itemsWithAppliedMutations.active,
    ),
    completedShoppingItems: sortFunctions.sortCompletedShoppingItems(
      itemsWithAppliedMutations.completed,
    ),
    originalShoppingItems: activeShoppingItems.concat(completedShoppingItems),
    mutations,
    sort: initialSortType,
  };
};

export { getInitialShoppingListState, getEmptyShoppingListState };
