import { AppError } from "../../entity/app-error";
import { decrypt, encrypt, sha256 } from "../../util/crypto";

const retrieveTokensByCode = async (
  code: string,
  secret: string,
  authProvider: AuthProvider,
  storageProvider: StorageProvider,
): Promise<string> => {
  const hashedSecret = await sha256(secret);
  const [accessToken, refreshToken] = await authProvider.retrieveTokensByCode(
    code,
    hashedSecret,
  );

  storageProvider.writeAccessToken(accessToken);
  const encryptedRefreshToken = await encrypt(refreshToken, secret);
  storageProvider.writeRefreshToken(encryptedRefreshToken);

  return accessToken;
};

const refreshToken = async (
  secret: string,
  authProvider: AuthProvider,
  storageProvider: StorageProvider,
): Promise<string> => {
  try {
    const refreshToken = storageProvider.readRefreshToken();
    if (refreshToken) {
      const depryptedRefreshToken = await decrypt(refreshToken, secret);
      const hashedSecret = await sha256(secret);
      const [accessToken, newRefreshToken] = await authProvider.refreshTokens(
        depryptedRefreshToken,
        hashedSecret,
      );
      storageProvider.writeAccessToken(accessToken);
      const encryptedRefreshToken = await encrypt(newRefreshToken, secret);
      storageProvider.writeRefreshToken(encryptedRefreshToken);

      return accessToken;
    }

    throw new AppError("AUTH_REQUIRED");
  } catch (error) {
    throw new AppError("AUTH_REQUIRED");
  }
};

const getToken = (storageProvider: StorageProvider) => {
  const token = storageProvider.readAccessToken();

  if (token) {
    return token;
  }

  throw new AppError("KNOWN_ERROR", "missing auth token");
};

const withRefreshToken = async <A>(
  secret: string,
  apiCall: (token: string) => A,
  authProvider: AuthProvider,
  storageProvider: StorageProvider,
) => {
  try {
    const token = getToken(storageProvider);
    return await apiCall(token);
  } catch (error) {
    if (error instanceof AppError && error.errorType === "AUTH_REQUIRED") {
      const token = await refreshToken(secret, authProvider, storageProvider);
      return await apiCall(token);
    }
    throw error;
  }
};

export { getToken, retrieveTokensByCode, refreshToken, withRefreshToken };
