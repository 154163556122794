import { useState, useEffect, useCallback, useRef } from "react";
import { updateShoppingListStateAction } from "./shopping-list-action";
import {
  countRemainingShoppingItemChanges,
  downloadShoppingItems,
} from "./shopping-list-download";
import { AppError } from "../../entity/app-error";
import { handleErrorAction } from "../error/error-action";
import { MIN_LOADING_DURATION } from "../../config/min-loading-duration";
import { uploadShoppingItemMutations } from "./shopping-list-mutation-upload";
import { useVisibility } from "../../util/visibility-hook";
import { useConnectivity } from "../../util/connectivity-hook";
import { startAnimation } from "../../util/animation-timeout";
import { createPolling } from "../../util/polling";

const useShoppingListSync = (
  secret: string,
  context: AppContext,
  authProvider: AuthProvider,
  storageProvider: StorageProvider,
  shoppingListProvider: ShoppingListProvider,
  visibilityProvider: VisibilityProvider,
  connectivityProvider: ConnectivityProvider,
) => {
  const { state, dispatch } = context;

  const mutations = state.shoppingListState.mutations;
  const activeShoppingItems = state.shoppingListState.activeShoppingItems;
  const completedShoppingItems = state.shoppingListState.completedShoppingItems;
  const shoppingItemsLength = activeShoppingItems.concat(
    completedShoppingItems,
  ).length;

  const [isOffline, setIsOffline] = useConnectivity(connectivityProvider);
  const [isVisible] = useVisibility(visibilityProvider);
  const [isAuthenticationRequired, setIsAuthenticationRequired] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [changesCount, setChangesCount] = useState<number>(0);
  const pollingCountRemainingShoppingItems = useRef<Polling>(createPolling(10));

  const handleDefaultErrors = (
    error: any,
    setIsAuthenticationRequired: (isAuthenticationRequired: boolean) => void,
    setIsOffline: (isOffline: boolean) => void,
  ) => {
    if (error && error instanceof AppError) {
      if (error.errorType === "AUTH_REQUIRED") {
        setIsAuthenticationRequired(true);
      } else if (error.errorType === "NETWORK_ERROR") {
        setIsOffline(true);
      }
    }
    console.error(error);
  };

  const uploadShoppingItemMutationsCallback = useCallback(async () => {
    try {
      await uploadShoppingItemMutations(
        secret,
        (newState) => {
          setIsAuthenticationRequired(false);
          updateShoppingListStateAction(dispatch, newState);
        },
        authProvider,
        storageProvider,
        shoppingListProvider,
      );
      setIsOffline(false);
      setIsAuthenticationRequired(false);
    } catch (error) {
      handleDefaultErrors(error, setIsAuthenticationRequired, setIsOffline);
    }
  }, [
    dispatch,
    secret,
    authProvider,
    storageProvider,
    shoppingListProvider,
    setIsOffline,
  ]);

  const countRemainingShoppingItemChangesCallback = useCallback(async () => {
    try {
      const count = await countRemainingShoppingItemChanges(
        secret,
        activeShoppingItems,
        authProvider,
        storageProvider,
        shoppingListProvider,
      );
      setIsOffline(false);
      setIsAuthenticationRequired(false);
      setChangesCount(count);
    } catch (error) {
      handleDefaultErrors(error, setIsAuthenticationRequired, setIsOffline);
    }
  }, [
    secret,
    activeShoppingItems,
    setIsOffline,
    authProvider,
    storageProvider,
    shoppingListProvider,
  ]);

  const updateShoppingListCallback = useCallback(async () => {
    const animation = startAnimation(MIN_LOADING_DURATION);
    try {
      setIsLoading(true);
      await downloadShoppingItems(
        secret,
        (newState) => {
          updateShoppingListStateAction(dispatch, newState);
        },
        authProvider,
        storageProvider,
        shoppingListProvider,
      );
      setIsOffline(false);
      setIsAuthenticationRequired(false);
      setChangesCount(0);
    } catch (error) {
      handleDefaultErrors(error, setIsAuthenticationRequired, setIsOffline);
      handleErrorAction(dispatch, error);
    } finally {
      await animation.endAnimation();
      setIsLoading(false);
    }
  }, [
    authProvider,
    dispatch,
    secret,
    setIsOffline,
    shoppingListProvider,
    storageProvider,
  ]);

  // activate polling for changes on server
  useEffect(() => {
    pollingCountRemainingShoppingItems.current.setCallback(
      countRemainingShoppingItemChangesCallback,
    );

    if (isVisible && !isOffline && !isAuthenticationRequired) {
      pollingCountRemainingShoppingItems.current.start();
    } else {
      pollingCountRemainingShoppingItems.current.stop();
    }
  }, [
    isVisible,
    isOffline,
    isAuthenticationRequired,
    countRemainingShoppingItemChangesCallback,
    pollingCountRemainingShoppingItems,
  ]);

  // stop polling when component is destroyed
  useEffect(() => {
    const pollingCurrent = pollingCountRemainingShoppingItems.current;
    return () => {
      if (pollingCurrent) {
        pollingCurrent.stop();
      }
    };
  }, []);

  // load shopping items after login / if no shopping items available
  useEffect(() => {
    if (shoppingItemsLength === 0) {
      updateShoppingListCallback();
    }
  }, [updateShoppingListCallback, shoppingItemsLength]);

  // upload mutations
  useEffect(() => {
    storageProvider.writeShoppingListMutations(mutations);
    if (
      !isOffline &&
      !isAuthenticationRequired &&
      isVisible &&
      mutations.length
    ) {
      uploadShoppingItemMutationsCallback().then(
        countRemainingShoppingItemChangesCallback,
      );
    }
  }, [
    mutations,
    isAuthenticationRequired,
    isOffline,
    isVisible,
    uploadShoppingItemMutationsCallback,
    countRemainingShoppingItemChangesCallback,
    updateShoppingListCallback,
    storageProvider,
    shoppingItemsLength,
  ]);

  return {
    isOffline,
    isAuthenticationRequired,
    isLoading,
    changesCount,
    updateShoppingList: updateShoppingListCallback,
  };
};

export { useShoppingListSync };
