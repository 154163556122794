import "./bottom-bar.css";

const BottomBarButton = ({
  label,
  screen,
  disabled,
  active,
  onClick,
}: BottomBarButtonProps) => {
  const onButtonClick = () => {
    if (onClick && !disabled && screen) {
      onClick(screen);
    }
  };
  return (
    <button
      className={!!active ? "active" : undefined}
      disabled={disabled === undefined ? false : disabled}
      onClick={onButtonClick}
    >
      {label}
    </button>
  );
};

const BottomBar = ({ currentScreen, onClick }: BottomBarProps) => {
  return (
    <div className="bottom-bar">
      <ul>
        <li>
          <BottomBarButton
            label="Einkaufsliste"
            screen={"SHOPPING_LIST"}
            active={currentScreen === "SHOPPING_LIST"}
            onClick={onClick}
          />
        </li>
        <li>
          <BottomBarButton label="Vorschläge" disabled={true} />
        </li>
        <li>
          <BottomBarButton
            label="Einstellungen"
            screen={"SETTINGS"}
            active={currentScreen === "SETTINGS"}
            onClick={onClick}
          />
        </li>
      </ul>
    </div>
  );
};

export { BottomBar };
