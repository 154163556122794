const readParameterFromAddressBar = (parameterName: string): string | null => {
  const search = window.location.search;
  if (search) {
    const codeParameter = search
      .substring(1)
      .split("&")
      .map((parameter) => {
        const [name, value] = parameter.split("=");
        return { name, value };
      })
      .find((parameter) => parameter.name === parameterName);

    if (codeParameter) {
      return codeParameter.value;
    }
  }
  return null;
};

const resetAddressBar = (): void => {
  window.history.replaceState(null, "", "index.html");
};

export { readParameterFromAddressBar, resetAddressBar };
