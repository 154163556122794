import * as authProvider from "../../api/auth-provider";
import * as storageProvider from "../../api/storage-provider";
import { login } from "../../domain/auth/auth-login";
import "./login-screen.css";

export const LoginScreen = () => {
  const loginWithAmazon = () => login(authProvider, storageProvider);

  return (
    <div className="login-screen">
      <h1>Einkaufsliste</h1>
      <p>
        Für die Nutzung des Angebots wird ein Amazon-Zugang benötigt. Durch
        Benutzung des Button "Anmelden" stimmen Sie der{" "}
        <a href="privacy.html" target="_blank">
          Datenschutzerklärung
        </a>{" "}
        und der Nutzung des Services "Login With Amazon" zu.
      </p>
      <img
        className="amazon-button"
        alt="Login with Amazon"
        onClick={loginWithAmazon}
        src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png"
        width="156"
        height="32"
      />
    </div>
  );
};
