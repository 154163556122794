import { initialErrorState } from "../error/error-state";
import { initialNavigationState } from "../navigation/navigation-state";
import { getInitialShoppingListState } from "../shopping-list/shopping-list-state";

const getInitialAppState = (storageProvider: StorageProvider): AppState => ({
  navigationState: initialNavigationState,
  errorState: initialErrorState,
  shoppingListState: getInitialShoppingListState(storageProvider),
  secret: storageProvider.readSecret(),
});

export { getInitialAppState };
