import { useReducer } from "react";
import "./app.css";
import { getInitialAppState } from "../../domain/app/app-state";
import combineReducers from "react-combine-reducers";
import { navigationReducer } from "../../domain/navigation/navigation-reducer";
import { errorReducer } from "../../domain/error/error-reducer";
import { shoppingListReducer } from "../../domain/shopping-list/shopping-list-reducer";
import { AppContext } from "../../domain/app/app-context";
import { Navigation } from "../navigation/navigation";
import * as storageProvider from "../../api/storage-provider";

const App = () => {
  const initialAppState = getInitialAppState(storageProvider);

  const [combinedAppReducer, combinedAppState] = combineReducers({
    navigationState: [navigationReducer, initialAppState.navigationState],
    errorState: [errorReducer, initialAppState.errorState],
    shoppingListState: [shoppingListReducer, initialAppState.shoppingListState],
  });

  const [state, dispatch] = useReducer(combinedAppReducer, combinedAppState);
  const secret = initialAppState.secret;

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <div className="app">
        <Navigation secret={secret} />
      </div>
    </AppContext.Provider>
  );
};

export { App };
