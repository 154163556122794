import { handleErrorAction } from "../error/error-action";
import { login } from "./auth-login";

const useAuth = (
  context: AppContext,
  authProvider: AuthProvider,
  storageProvider: StorageProvider,
) => {
  const { dispatch } = context;

  const startLogin = async () => {
    try {
      await login(authProvider, storageProvider);
    } catch (error) {
      handleErrorAction(dispatch, error);
    }
  };

  return {
    startLogin,
  };
};

export { useAuth };
