const encrypt = async (content: string, password: string): Promise<string> => {
  const salt = crypto.getRandomValues(new Uint8Array(16));

  const key = await getKey(password, salt);

  const iv = crypto.getRandomValues(new Uint8Array(12));

  const contentBytes = stringToBytes(content);

  const cipher = new Uint8Array(
    await crypto.subtle.encrypt({ name: "AES-GCM", iv }, key, contentBytes),
  );

  return base64Encode(
    JSON.stringify({
      salt: bytesToBase64(salt),
      iv: bytesToBase64(iv),
      cipher: bytesToBase64(cipher),
    }),
  );
};

const decrypt = async (content: string, password: string): Promise<string> => {
  const encryptedData: EncryptedData = JSON.parse(base64Decode(content));
  const salt = base64ToBytes(encryptedData.salt);

  const key = await getKey(password, salt);

  const iv = base64ToBytes(encryptedData.iv);

  const cipher = base64ToBytes(encryptedData.cipher);

  const contentBytes = new Uint8Array(
    await crypto.subtle.decrypt({ name: "AES-GCM", iv }, key, cipher),
  );

  return bytesToString(contentBytes);
};

const getKey = async (password: string, salt: BufferSource) => {
  const passwordBytes = stringToBytes(password);

  const initialKey = await crypto.subtle.importKey(
    "raw",
    passwordBytes,
    { name: "PBKDF2" },
    false,
    ["deriveKey"],
  );

  return crypto.subtle.deriveKey(
    { name: "PBKDF2", salt, iterations: 100000, hash: "SHA-256" },
    initialKey,
    { name: "AES-GCM", length: 256 },
    false,
    ["encrypt", "decrypt"],
  );
};

const sha256 = async (content: string) => {
  const h = await crypto.subtle.digest(
    "SHA-256",
    new TextEncoder().encode(content),
  );

  let hexes = [],
    view = new DataView(h);
  for (let i = 0; i < view.byteLength; i += 4)
    hexes.push(("00000000" + view.getUint32(i).toString(16)).slice(-8));
  return hexes.join("");
};

const bytesToString = (bytes: Uint8Array) => {
  return new TextDecoder().decode(bytes);
};

const stringToBytes = (str: string) => {
  return new TextEncoder().encode(str);
};

const bytesToBase64 = (arr: Uint8Array) => {
  return btoa(Array.from(arr, (b) => String.fromCharCode(b)).join(""));
};

const base64ToBytes = (base64: string) => {
  return Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));
};

const base64Encode = (str: string) => {
  return btoa(unescape(encodeURIComponent(str)));
};
const base64Decode = (str: string) => {
  return decodeURIComponent(escape(atob(str)));
};

export { encrypt, decrypt, sha256, base64Encode, base64Decode };
