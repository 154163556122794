import { runInQueue } from "../../util/control-flow";
import { withRefreshToken } from "../auth/auth-token";
import { getDefaultShoppingListId } from "../default-shopping-list-id";

const mapToShoppingItems = (response: ShoppingItemResponse): ShoppingItem[] =>
  response.items.map((item) => ({
    id: item.id,
    status: item.status,
    value: item.value,
    version: item.version,
    createdTime: new Date(item.createdTime),
    updatedTime: new Date(item.updatedTime),
  }));

const downloadShoppingItems = async (
  secret: string,
  updateCallback: (state: UpdateShoppingListStatePayload) => void,
  authProvider: AuthProvider,
  storageProvider: StorageProvider,
  shoppingListProvider: ShoppingListProvider,
) =>
  await runInQueue<void>(async () => {
    const listId = await getDefaultShoppingListId(
      secret,
      authProvider,
      storageProvider,
      shoppingListProvider,
    );

    const responseActiveItems = await withRefreshToken<
      Promise<ShoppingItemResponse>
    >(
      secret,
      async (token) =>
        await shoppingListProvider.loadShoppingItems(token, listId, "active"),
      authProvider,
      storageProvider,
    );
    const activeItems = mapToShoppingItems(responseActiveItems);
    storageProvider.writeActiveShoppingItems(activeItems);

    const responseCompletedItems = await withRefreshToken<
      Promise<ShoppingItemResponse>
    >(
      secret,
      async (token) =>
        await shoppingListProvider.loadShoppingItems(
          token,
          listId,
          "completed",
        ),
      authProvider,
      storageProvider,
    );
    const completedItems = mapToShoppingItems(responseCompletedItems);
    storageProvider.writeCompletedShoppingItems(completedItems);

    updateCallback({
      activeShoppingItems: activeItems,
      completedShoppingItems: completedItems,
      originalShoppingItems: activeItems.concat(completedItems),
    });
  });

// todo runinQueue
const countRemainingShoppingItemChanges = async (
  secret: string,
  localActiveShoppingItems: ShoppingItem[],
  authProvider: AuthProvider,
  storageProvider: StorageProvider,
  shoppingListProvider: ShoppingListProvider,
): Promise<number> =>
  await runInQueue<number>(async () => {
    const defaultShoppingListId = await getDefaultShoppingListId(
      secret,
      authProvider,
      storageProvider,
      shoppingListProvider,
    );

    const responseActiveItems = await withRefreshToken<
      Promise<ShoppingItemResponse>
    >(
      secret,
      async (token) =>
        await shoppingListProvider.loadShoppingItems(
          token,
          defaultShoppingListId,
          "active",
        ),
      authProvider,
      storageProvider,
    );
    const remoteActiveShoppingItems = mapToShoppingItems(responseActiveItems);

    let counter = 0;

    counter =
      counter +
      localActiveShoppingItems
        .filter(
          (localItem) =>
            !remoteActiveShoppingItems
              .map((remoteItem) => remoteItem.id)
              .includes(localItem.id),
        )
        .concat(
          remoteActiveShoppingItems.filter(
            (remoteItem) =>
              !localActiveShoppingItems
                .map((localItem) => localItem.id)
                .includes(remoteItem.id),
          ),
        ).length;
    counter =
      counter +
      localActiveShoppingItems.filter((localItem) => {
        const matchedRemoteItem = remoteActiveShoppingItems.find(
          (remoteItem) => remoteItem.id === localItem.id,
        );
        if (matchedRemoteItem) {
          return (
            matchedRemoteItem.status !== localItem.status ||
            matchedRemoteItem.value !== localItem.value
          );
        }
        return false;
      }).length;

    return counter;
  });

export { downloadShoppingItems, countRemainingShoppingItemChanges };
