import { AppError } from "../entity/app-error";

const openURL = (url: string, target: LocationTarget) => {
  switch (target) {
    case "_self":
      window.location.href = url;
      break;
    case "_blank":
      window.open(url, "_blank");
      break;
    default:
      throw new AppError("KNOWN_ERROR", "location target unknown " + target);
  }
};

export { openURL };
