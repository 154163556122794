import "./button.css";

export const Button = ({
  text,
  onClick,
  disabled = false,
}: {
  readonly text: string;
  readonly onClick: () => void;
  readonly disabled?: boolean;
}) => {
  return (
    <button className="button" onClick={onClick} disabled={disabled}>
      {text}
    </button>
  );
};
