import { useCallback, useEffect, useState } from "react";

const useConnectivity = (
  connectivityProvider: ConnectivityProvider,
): [boolean, (isOffline: boolean) => void] => {
  const [isOffline, setIsOffline] = useState<boolean>(
    !connectivityProvider.isOnline(),
  );

  const listener = useCallback(
    () => setIsOffline(!connectivityProvider.isOnline()),
    [setIsOffline, connectivityProvider],
  );

  useEffect(() => {
    connectivityProvider.addConnectivityListener(listener);
    return () => {
      connectivityProvider.removeConnectivityListener(listener);
    };
  }, [listener, connectivityProvider]);

  return [isOffline, setIsOffline];
};

export { useConnectivity };
