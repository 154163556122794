import { Dispatch } from "react";

const handleErrorAction = (
  dispatch: Dispatch<HandleErrorAction>,
  error: Error | unknown,
) => {
  dispatch({
    type: "HANDLE_ERROR",
    payload: { error },
  });
};

const resetErrorAction = (dispatch: Dispatch<ResetErrorAction>) => {
  dispatch({
    type: "RESET_ERROR",
  });
};

export { handleErrorAction, resetErrorAction };
