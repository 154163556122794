const navigationReducer = (
  oldState: NavigationState,
  action: NavigationAction,
): NavigationState => {
  switch (action.type) {
    case "GOTO_SCREEN_ACTION":
      return { ...oldState, currentScreen: action.payload.screen };
    default:
      return oldState;
  }
};

export { navigationReducer };
