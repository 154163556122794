import { AppError } from "../../entity/app-error";

const getOtherShoppingItemStatus = (
  status: ShoppingItemStatus,
): ShoppingItemStatus => (status === "active" ? "completed" : "active");

const getShoppingItemsByStatus = (
  status: ShoppingItemStatus,
  activeShoppingItems: ShoppingItem[],
  completedShoppingItems: ShoppingItem[],
): ShoppingItem[] => {
  switch (status) {
    case "active":
      return activeShoppingItems;
    case "completed":
      return completedShoppingItems;
    default:
      throw new AppError(
        "KNOWN_ERROR",
        "unknown shopping item status: " + status,
      );
  }
};

const applyMutationsToShoppingItems = (
  mutations: ShoppingItemMutation[],
  activeShoppingItemsFromState: ShoppingItem[],
  completedShoppingItemsFromState: ShoppingItem[],
) =>
  mutations.reduce(
    (prev: any, mutation: ShoppingItemMutation) =>
      applyMutationToShoppingItems(mutation, prev.active, prev.completed),
    {
      active: activeShoppingItemsFromState,
      completed: completedShoppingItemsFromState,
    },
  );

const applyMutationToShoppingItems = (
  mutation: ShoppingItemMutation,
  activeShoppingItemsFromState: ShoppingItem[],
  completedShoppingItemsFromState: ShoppingItem[],
): { [key: string]: ShoppingItem[] } => {
  const item = activeShoppingItemsFromState
    .concat(completedShoppingItemsFromState)
    .find((item) => item.id === mutation.itemId);

  if (item) {
    switch (mutation.type) {
      case "DELETION":
        const status = item.status;
        const otherStatus = getOtherShoppingItemStatus(status);

        return {
          [status]: getShoppingItemsByStatus(
            status,
            activeShoppingItemsFromState,
            completedShoppingItemsFromState,
          ).filter((item) => item.id !== mutation.itemId),
          [otherStatus]: getShoppingItemsByStatus(
            otherStatus,
            activeShoppingItemsFromState,
            completedShoppingItemsFromState,
          ),
        };
      case "UPDATE":
        if (item.status === mutation.data.status) {
          const status = item.status;
          const otherStatus = getOtherShoppingItemStatus(status);
          return {
            [status as ShoppingItemStatus]: getShoppingItemsByStatus(
              status,
              activeShoppingItemsFromState,
              completedShoppingItemsFromState,
            ).map((item: ShoppingItem) => {
              if (item.id === mutation.itemId) {
                return {
                  ...item,
                  status: mutation.data.status,
                  value: mutation.data.value,
                  version: mutation.data.version + 1,
                  updatedTime: new Date(),
                };
              }
              return item;
            }),
            [otherStatus as ShoppingItemStatus]: getShoppingItemsByStatus(
              otherStatus,
              activeShoppingItemsFromState,
              completedShoppingItemsFromState,
            ),
          };
        } else {
          const itemStatus = item.status;
          const mutationStatus = mutation.data.status;
          return {
            [itemStatus]: getShoppingItemsByStatus(
              itemStatus,
              activeShoppingItemsFromState,
              completedShoppingItemsFromState,
            ).filter((item: ShoppingItem) => item.id !== mutation.itemId),
            [mutationStatus]: [
              {
                ...item,
                value: mutation.data.value,
                status: mutation.data.status,
                version: mutation.data.version + 1,
              },
              ...getShoppingItemsByStatus(
                mutationStatus,
                activeShoppingItemsFromState,
                completedShoppingItemsFromState,
              ),
            ],
          };
        }

      default:
        throw new AppError(
          "KNOWN_ERROR",
          "unknown mutation type: " + mutation.type,
        );
    }
  } else {
    throw new AppError(
      "KNOWN_ERROR",
      "could not find item: " + mutation.itemId,
    );
  }
};

export { applyMutationsToShoppingItems, applyMutationToShoppingItems };
