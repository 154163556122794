import { AppError } from "../entity/app-error";
import { PREFIX_TOKEN_API } from "../config/api-prefix";
import { REDIRECT_URI } from "../config/redirect-uri";

const login = () => {
  const params = [
    { key: "scope", value: "profile" },
    { key: "response_type", value: "code" },
    { key: "redirect_uri", value: REDIRECT_URI },
  ];

  window.location.href =
    "/login?" + params.map((p) => p.key + "=" + encodeURI(p.value)).join("&");
};

const loadTokensBy = async (
  request: AmazonCodeRequest | AmazonRefreshTokenRequest,
  secretHash: string,
): Promise<AmazonTokenResponse> => {
  const response = await fetch(PREFIX_TOKEN_API + "/auth/o2/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-SECRET-HASH": secretHash,
    },
    body: JSON.stringify(request),
  });

  if (response.status === 200) {
    const jsonResponse = await response.json();

    if (!jsonResponse.error) {
      return jsonResponse;
    }
  }

  throw new AppError("TOKEN_ERROR", "error retrieving tokens");
};

const retrieveTokensByCode = async (
  code: string,
  secretHash: string,
): Promise<string[]> => {
  const request: AmazonCodeRequest = {
    grant_type: "authorization_code",
    code,
    redirect_uri: REDIRECT_URI,
  };
  const response = await loadTokensBy(request, secretHash);

  if (response.refresh_token && response.access_token) {
    return [response.access_token, response.refresh_token];
  }

  throw new AppError("KNOWN_ERROR", "missing tokens");
};

const refreshTokens = async (
  refreshToken: string,
  secretHash: string,
): Promise<string[]> => {
  const request: AmazonRefreshTokenRequest = {
    grant_type: "refresh_token",
    refresh_token: refreshToken,
    redirect_uri: REDIRECT_URI,
  };
  const response = await loadTokensBy(request, secretHash);

  if (response.refresh_token && response.access_token) {
    return [response.access_token, response.refresh_token];
  }

  throw new AppError("KNOWN_ERROR", "missing refresh token");
};

const logout = async (refreshToken: string, secretHash: string) => {
  // refresh tokens to invalidate previous saved tokens
  await refreshTokens(refreshToken, secretHash);
};

export { login, retrieveTokensByCode, refreshTokens, logout };
